.App {
  text-align: center;
  justify-content: center;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.panel1 {
  flex: 1;
  /*overflow: auto;*/
  scrollbar-width: auto;
  scrollbar-color: rgba(0,0,0,.4) rgba(0,0,0,0);
  /*background-color: #f9f9fa;*/
 /* border-radius: 0 0 4px 4px;*/
  margin-top: 5px;
  padding: 5px 0px 0px 10px;
  color: #262a32;
  font-size: 12px;
  line-height: 1.5715;
  list-style: none;
  height: auto;
  
}

.panel2 {
  /*background-color: #f9f9fa;*/
  min-height: calc(100% - 74px);
  color: #262a32;
  font-size: 12px;
  line-height: 1.5715;
  list-style: none;
  margin: 0;
  padding: 0;
  display: block;
  height: auto;
  /*height: 50px;*/
}

.lookup1 {
  /*background-color: #f9f9fa;*/
  padding: 2px;
  box-sizing: initial;
  margin: 0;
  display: block;
}

.lookup2 {
  width: 100%;
  position: relative;
  align-items: center;
  margin-bottom: 12px;
  margin: 0;
  padding: 0;
  color: #262a32;
  font-size: 12px;
  line-height: 1.5715;
  list-style: none;
}

.lookup3 {
  /*position: absolute;*/
  display: inline-block;
}

.lookup4 {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 120;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  list-style: none;
  display: none;
  height: 150px;
  width: 330px;
  overflow-y: auto;
}

.input4 {
  width: 330px;
  height: 250px;
  resize: none;
  box-sizing: border-box;
  padding: 3px 8px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.input3 {
  padding: 3px 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 330px;
  font-size: 14px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  text-decoration: underline;
  color: blue;
  display: inline-block;
  height: 35px;
  line-height: 35px;
}
  .input3:hover {
      font-size: 16px;
  }

  .date-picker-input input {
    width: 200px; /* Nastavte šířku podle vašich potřeb */
    height: 25px; /* Nastavte výšku podle vašich potřeb */
}

.hlava {
  background-color: #1C80BC;
  font-size: 14px;
  color: #FFFFFF;
  /*position: fixed;*/
  font-weight: bold;
  text-align: center;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  z-index: 100;
  /*margin: 20px 0px 20px 0px;*/
  outline: none;
  text-decoration: none;
  margin-bottom: 20px;
}
  .hlava:hover {
    font-size: 16px;
  }

.detail-section {
    margin-top: 20px;
}

body {
  padding-top: calc(0px + 10px); /* Replace 100% with the height of your header */
}

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  top: 0px;
  width: 100%;
  margin-bottom: 10px;
}

.menu-container {
  margin-left: 10px;
}

.logo {
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  height: auto;
  width: 50%;
 

}

.no-underline:before {
  display: none;
}

.flex-container {
  display: flex;
  flex-direction: column;
  height: 77vh; /* Zabere celou výšku viewportu */
  overflow: auto; /* Zajistí, že obsah uvnitř kontejneru bude skrolovatelný, pokud přesáhne výšku */
}

.fixed-header {
  position: fixed; /* Udrží prvek pevně na místě */
  top: 0; /* Zarovná prvek na horní část obrazovky */
  left: 0; /* Zarovná prvek na levou stranu obrazovky */
  width: 100%; /* Roztáhne prvek na plnou šířku obrazovky */
  z-index: 1000; /* Zajistí, že prvek bude nad ostatními prvky stránky */
  /* Další styly pro vzhled záhlaví, například barva pozadí, padding, atd. */
  background-color: #fff; /* Příklad: bílé pozadí */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Přidává lehký stín pro vizuální oddělení od obsahu */
  padding: 10px 20px; /* Přidává padding pro vnitřní obsah záhlaví */
}


.ag-grid-container {
  flex: 1; /* Tímto zajistíte, že ag-Grid kontejner zaplní zbývající prostor */
  width: 100%;
}

.ag-header-icon.ag-header-cell-menu-button {
  font-size: 50px; /* Nastavte požadovanou velikost */
}

.headerClass-tableKalk {
  white-space: normal;
  overflow: visible;
  text-overflow: ellipsis;
  word-wrap: break-word;
  line-height: 1.4;
  padding: 1px !important;
  
}

.headerClass-tableKalk .ag-header-cell-label {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

}

.cellClass-tableKalk {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  word-wrap: break-word;
  padding: 1px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cellClass-tablePole {
  white-space: normal;
  overflow: visible;
  text-overflow: clip;
  word-wrap: break-word;
  padding: 1px !important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
 
}

.special-header {
  background-color: '#b3d1f3';
}

.header-skupina {
  background-color: #FFD700; /* Zlatá barva pro Skupina */
  color: black; /* Textová barva */
}

.header-podskupina {
  background-color: #32CD32; /* Zelená barva pro Podskupina */
  color: white; /* Textová barva */
}

.header-polozky-hlavni {
  background-color: #4682B4; /* Modrá barva pro Položky hlavní */
  color: white; /* Textová barva */
}

.header-prirazeni {
  background-color: #FFA07A; /* Lososová barva pro Přiřazení do entit */
  color: black; /* Textová barva */
}

.header-automaticke {
  background-color: #D8BFD8; /* Tmavá orchidejová barva pro Automatické přiřazení */
  color: black; /* Textová barva */
}

.gantt_task_line.task {
  background-color: #3DB9D3;
}


.gantt_task_line.gantt_project{
  color:white;
}

.gantt_side_content{
  color:#333;
}

.summary-bar{
  font-weight: bold;
}

.gantt_resource_task .gantt_task_content{
  color:inherit;
}

.gantt_resource_task .gantt_task_progress{
  background-color:rgba(33,33,33,0.3);
}

.gantt_cell:nth-child(1) .gantt_tree_content{
  border-radius: 16px;
  width: 100%;
  height: 80%;
  margin: 5% 0;
  line-height: 230%;
}

#gantt_here {
  width:100%;
  height: 800px;
  height:calc(100vh - 52px);
}

.gantt_grid_scale .gantt_grid_head_cell,
.gantt_task .gantt_task_scale .gantt_scale_cell {
  font-weight: bold;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.7);
}

.folder_row {
  font-weight: bold;
}

.highlighted_resource,
.highlighted_resource.odd {
  background-color: rgba(255, 251, 224, 0.6);
}

.gantt_task_cell.week_end {
  background-color: #e8e8e87d;
}

.gantt_task_row.gantt_selected .gantt_task_cell.week_end {
  background-color: #e8e8e87d !important;
}


.group_row,
.group_row.odd,
.gantt_task_row.group_row {
  background-color: rgba(232, 232, 232, 0.6);
}

.owner-label {
  width: 20px;
  height: 20px;
  line-height: 20px;
  font-size: 12px;
  display: inline-block;
  border: 1px solid #cccccc;
  border-radius: 25px;
  background: #e6e6e6;
  color: #6f6f6f;
  margin: 0 3px;
  font-weight: bold;
}


.resource-select-panel .gantt_layout_content {
  line-height: 35px;
  text-align: right;
  padding-right: 15px;
  overflow: hidden !important;
}
.resource-select {
  padding: 3px;
  margin-left: 13px;
}

.column_overload .gantt_histogram_fill {
  background-color:#ffa9a9;
}

.actual-task .gantt_task_content {
  background-color: #7d90ab; /* modrá barva pro skutečné úkoly */
  opacity: 1; /* průhlednost */
  color: white;
}

.mapa-task .gantt_task_content {
  background-color: #74a6ed; /* světle modrá barva pro plánované úkoly */
  opacity: 1; /* průhlednost */
  color: white;
}

.milestone-mapa-task .gantt_task_content,
.milestone-mapa-task .gantt_task_line {
  border: 0px solid red !important; /* Červený rámeček pro ladění */
  background-color: #74a6ed !important; /* světle modrá barva pro plánované milestone úkoly */
}

.milestone-actual-task .gantt_task_content,
.milestone-actual-task .gantt_task_line {
  border: 0px solid blue !important;  /* Modrý rámeček pro ladění */
  background-color: #7d90ab !important; /* červená barva pro skutečné milestone úkoly */
}

.today {
  background-color: #74a6ed !important; /* oranžová barva pro plánované milestone úkoly */
  color: #0b6bf1 !important;
}

.button-gantt1 {
  border: 1px solid #ccc; /* Přidání okraje */
  padding: 5px 10px; /* Přidání vnitřního odsazení pro lepší vzhled */
  border-radius: 4px; /* Přidání zaoblení okrajů */
  background-color: white; /* Přidání pozadí */
  color: blue; /* Nastavení barvy textu */
  cursor: pointer; /* Změna kurzoru při najetí myší */
}

.button-gantt1:hover {
  background-color: #f0f0f0; /* Změna pozadí při najetí myší */
}

.overload {
  background-color: red !important;
}
